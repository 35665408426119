<template>
  <div class="event">
    <div class="title">
      玄武盃賽事
    </div>
    <div ref="scheduleBlock">
      <div class="schedule">
        <div class="item" v-for="(item, i) in schedule" :key="i">
          <div class="header">
            <span class="date">{{ item.date }}</span>
            <span class="day">{{ item.day }}</span>
          </div>
          <div class="tbody">
            <!-- <div class="type">
              <span class="type">{{ item.type }}</span>
              <span class="text">GROUP STAGE</span>
            </div> -->
            <div class="teams">
              <div
                class="team"
                v-for="(team, i) in item.teams"
                :key="i"
                :style="`background-color: ${findTeam(team).color}`"
                @click="changeTeam(team)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="schedule web">
        <div class="item" v-for="(item, i) in schedule1" :key="i">
          <div class="header">
            <span class="date">{{ item.date }}</span>
            <span class="day">{{ item.day }}</span>
          </div>
          <div class="tbody">

            <div class="teams">
              <div
                class="team"
                v-for="(team, i) in item.teams"
                :key="i"
                :style="`background-color: ${findTeam(team).color}`"
                @click="changeTeam(team)"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="schedule web">
        <div class="item" v-for="(item, i) in schedule2" :key="i">
          <div class="header">
            <span class="date">{{ item.date }}</span>
            <span class="day">{{ item.day }}</span>
          </div>
          <div class="tbody">
            <div class="teams">
              <div
                class="team"
                v-for="(team, i) in item.teams"
                :key="i"
                :style="`background-color: ${findTeam(team).color}`"
                @click="changeTeam(team)"
              ></div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="title" ref="playerPreview">玄武盃選手</div>
    <div class="teams">
      <div class="teamIcons">
        <div class="icon" v-for="(team, i) in teamPlayers" :key="i">
          <img :src="team.icon" @click="changeTeam(team.team)" />
        </div>
      </div>
      <div class="preview">
        <div class="playerDetail" v-for="(id, i) in previewPlayers" :key="i">
          <div class="player-game-data" v-if="isLoading">
            <div class="player-content">
              <div class="trigon">
                <div class="main">
                  <span :style="getTriangleTextCss()">自摸率</span>
                  <span :style="getTriangleTextCss()">胡牌率</span>
                  <span :style="getTriangleTextCss()">放槍率</span>
                  <multiTrigon
                    v-if="isLoading"
                    :width="300"
                    :height="300"
                    :bgcolor="transparent"
                    :cfgs="cfgs"
                    :datas="getTriangleData(id)"
                  />
                </div>
              </div>
              <div class="rows">
                <div class="item" v-show="page == 1">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 1)"
                    :style="titleTextCss('出場雀數')"
                  >
                    出場雀數
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(findPlayer(id).details.allGames)
                      )
                    "
                    v-text="findPlayer(id).details.allGames"
                  />
                </div>
                <div class="item" v-show="page == 2">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 2)"
                    :style="titleTextCss('總得分數')"
                  >
                    自摸率
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(
                          roundDecimalPercent(
                            (findPlayer(id).details.tsumoCount /
                              findPlayer(id).details.allHands) *
                              100,
                            2
                          )
                        )
                      )
                    "
                    v-text="
                      roundDecimalPercent(
                        (findPlayer(id).details.tsumoCount /
                          findPlayer(id).details.allHands) *
                          100,
                        2
                      )
                    "
                  />
                </div>
                <div class="item" v-show="page == 1">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 1)"
                    :style="titleTextCss('總得分數')"
                  >
                    總得分數
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(findPlayer(id).details.points)
                      )
                    "
                    v-text="findPlayer(id).details.points"
                  />
                </div>
                <div class="item" v-show="page == 2">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 2)"
                    :style="titleTextCss('平均得分')"
                  >
                    胡牌率
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(
                          roundDecimalPercent(
                            (findPlayer(id).details.winCount /
                              findPlayer(id).details.allHands) *
                              100,
                            2
                          )
                        )
                      )
                    "
                    v-text="
                      roundDecimalPercent(
                        (findPlayer(id).details.winCount /
                          findPlayer(id).details.allHands) *
                          100,
                        2
                      )
                    "
                  />
                </div>
                <div class="item" v-show="page == 1">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 1)"
                    :style="titleTextCss('平均得分')"
                  >
                    平均得分
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(
                          roundDecimal(
                            findPlayer(id).details.points /
                              findPlayer(id).details.allGames,
                            2
                          )
                        )
                      )
                    "
                    v-text="
                      roundDecimal(
                        findPlayer(id).details.points /
                          findPlayer(id).details.allGames,
                        2
                      )
                    "
                  />
                </div>
                <div class="item" v-show="page == 2">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 2)"
                    :style="titleTextCss('總得分數')"
                  >
                    放槍率
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(
                          roundDecimalPercent(
                            (findPlayer(id).details.loseCount /
                              findPlayer(id).details.allHands) *
                              100,
                            2
                          )
                        )
                      )
                    "
                    v-text="
                      roundDecimalPercent(
                        (findPlayer(id).details.loseCount /
                          findPlayer(id).details.allHands) *
                          100,
                        2
                      )
                    "
                  />
                </div>
                <div class="item" v-show="page == 1">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 1)"
                    :style="titleTextCss('單把最高')"
                  >
                    單把最高
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(findPlayer(id).details.maxPoint)
                      )
                    "
                    v-text="findPlayer(id).details.maxPoint"
                  />
                </div>
                <div class="item" v-show="page == 2">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 2)"
                    :style="titleTextCss('最高連莊')"
                  >
                    最高連莊
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(findPlayer(id).details.maxCombo)
                      )
                    "
                    v-text="findPlayer(id).details.maxCombo"
                  />
                </div>
                <div class="item" v-show="page == 1">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 1)"
                    :style="titleTextCss('自摸把數')"
                  >
                    自摸把數
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(findPlayer(id).details.tsumoCount)
                      )
                    "
                    v-text="findPlayer(id).details.tsumoCount"
                  />
                </div>
                <div class="item" v-show="page == 2">
                  <div
                    class="title"
                    :class="toggleAnimation(page == 2)"
                    :style="titleTextCss('平均連莊')"
                  >
                    平均連莊
                  </div>
                  <div
                    class="data"
                    :style="
                      valueTextCss(
                        JSON.stringify(
                          roundDecimal(
                            findPlayer(id).details.allCombo /
                              (findPlayer(id).details.allGames * 4),
                            2
                          )
                        )
                      )
                    "
                    v-text="
                      roundDecimal(
                        findPlayer(id).details.allCombo /
                          (findPlayer(id).details.allGames * 4),
                        2
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <img :src="findPlayer(id).image" />
        </div>
      </div>
      <!-- <div class="teamIcons">
        <div class="icon" v-for="(team, i) in teamLine2" :key="i">
          <img :src="team.icon" @click="changeTeam(team)" />
        </div>
      </div> -->
    </div>
    <div class="title">
      賽制說明
    </div>
    <div class="rule-desc">
      每場賽事結束後統計當日出戰排名，
      只有第一名的隊伍得１分，累積隊伍積分３分的隊伍，於季後賽結束後獲得冠軍；無其他比分加權（個人生涯戰績皆會記錄）。單場賽事中若複數隊伍同分則以自摸數較多隊伍勝出，若自摸數相同則以胡牌數較多隊伍勝出，若自摸數及胡牌數相同則放槍較少隊伍勝出。
      前3場次為季後賽，第4場之後的場次為延長賽。賽程會持續到有隊伍得到３分之後結束。
    </div>
    <rules></rules>
  </div>
</template>

<script>
import rules from "@/components/content/rules";
import multiTrigon from "@/components/majcmp/multiTrigon";
const A = require("@/assets/images/team-3/teamAicon_300x300.svg");
const B = require("@/assets/images/team-3/teamBicon_300x300.svg");
const C = require("@/assets/images/team-3/teamCicon_300x300.svg");
const D = require("@/assets/images/team-3/teamDicon_300x300.svg");
const E = require("@/assets/images/team-3/teamEicon_300x300.svg");
const F = require("@/assets/images/team-3/teamFicon_300x300.svg");
const G = require("@/assets/images/team-3/teamGicon_300x300.svg");
const H = require("@/assets/images/team-3/teamHicon_300x300.svg");

const A1 = require("@/assets/images/player-3/A1.jpg");
const A2 = require("@/assets/images/player-3/A2.jpg");
const B1 = require("@/assets/images/player-3/B1.jpg");
const B2 = require("@/assets/images/player-3/B2.jpg");
const C1 = require("@/assets/images/player-3/C1.jpg");
const C2 = require("@/assets/images/player-3/C2.jpg");
const D1 = require("@/assets/images/player-3/D1.jpg");
const D2 = require("@/assets/images/player-3/D2.jpg");
const E1 = require("@/assets/images/player-3/E1.jpg");
const E2 = require("@/assets/images/player-3/E2.jpg");
const F1 = require("@/assets/images/player-3/F1.jpg");
const F2 = require("@/assets/images/player-3/F2.jpg");
const G1 = require("@/assets/images/player-3/G1.jpg");
const G2 = require("@/assets/images/player-3/G2.jpg");
const H1 = require("@/assets/images/player-3/H1.jpg");
const H2 = require("@/assets/images/player-3/H2.jpg");

export default {
  name: "intro",
  components: { rules, multiTrigon },
  data() {
    return {
      teamLine1: [
        { team: "A", icon: A, color: "#9A7735", players: [196, 197] },
        { team: "B", icon: B, color: "#FF834B", players: [198, 199] },
        { team: "C", icon: C, color: "#A755EA", players: [200, 201] },
        { team: "D", icon: D, color: "#008E91", players: [202, 203] },
      ],
      teamLine2: [
        { team: "E", icon: E, color: "#FFC82C", players: [204, 205] },
        { team: "F", icon: F, color: "#CC1F30", players: [206, 207] },
        { team: "G", icon: G, color: "#98D34B", players: [208, 209] },
        { team: "H", icon: H, color: "#42A5E2", players: [210, 211] },
      ],
      players: [
        { id: 196, key: "A", name: "韋智", image: A2, details: {} },
        { id: 197, key: "B", name: "韋孟", image: A1, details: {} },
        { id: 198, key: "C", name: "陳研", image: B2, details: {} },
        { id: 199, key: "D", name: "耘耘", image: B1, details: {} },
        { id: 200, key: "E", name: "肉肉", image: C1, details: {} },
        { id: 201, key: "F", name: "涵涵", image: C2, details: {} },
        { id: 202, key: "G", name: "賊恩", image: D2, details: {} },
        { id: 203, key: "H", name: "令兒", image: D1, details: {} },
        { id: 204, key: "I", name: "貝卡", image: E1, details: {} },
        { id: 205, key: "J", name: "凱文", image: E2, details: {} },
        { id: 206, key: "K", name: "湘湘", image: F1, details: {} },
        { id: 207, key: "L", name: "綺綺", image: F2, details: {} },
        { id: 208, key: "M", name: "小于", image: G1, details: {} },
        { id: 209, key: "S", name: "歐歐", image: G2, details: {} },
        { id: 210, key: "O", name: "柯潔", image: H2, details: {} },
        { id: 211, key: "P", name: "哈娜", image: H1, details: {} },
      ],
      schedule: [
        { date: "02.03", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
        { date: "02.04", day: "TUE.", type: "S", teams: ["B", "C", "D", "F"] },
        { date: "02.10", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
        { date: "02.11", day: "TUE.", type: "S", teams: ["B", "C", "D", "F"] },
        { date: "02.17", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
        { date: "02.18", day: "TUE.", type: "S", teams: ["B", "C", "D", "F"] },
        { date: "02.24", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
        { date: "02.25", day: "TUE.", type: "S", teams: ["B", "C", "D", "F"] },
        { date: "03.03", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
      ],
      // schedule1: [
      //   { date: "12.02", day: "MON.", type: "N", teams: ["B", "C", "D", "F"] },
      //   { date: "12.03", day: "TUE.", type: "S", teams: ["A", "B", "D", "G"] },
      //   { date: "12.09", day: "MON.", type: "N", teams: ["A", "B", "C", "F"] },
      //   { date: "12.10", day: "TUE.", type: "S", teams: ["A", "C", "D", "F"] },
      //   { date: "12.16", day: "MON.", type: "N", teams: ["B", "D", "F", "G"] },
      //   { date: "12.17", day: "TUE.", type: "S", teams: ["B", "C", "F", "G"] },
      //   { date: "12.23", day: "MON.", type: "N", teams: ["A", "B", "C", "G"] },
      //   { date: "12.24", day: "TUE.", type: "S", teams: ["A", "D", "F", "G"] },
      // ],
      // schedule2: [
      //   { date: "12.30", day: "MON.", type: "N", teams: ["A", "C", "D", "G"] },
      //   { date: "01.06", day: "MON.", type: "S", teams: ["A", "B", "C", "D"] },
      //   { date: "01.07", day: "TUE.", type: "N", teams: ["A", "C", "F", "G"] },
      //   { date: "01.13", day: "MON.", type: "S", teams: ["B", "C", "D", "G"] },
      //   { date: "01.14", day: "TUE.", type: "N", teams: ["A", "B", "D", "F"] },
      //   { date: "01.20", day: "MON.", type: "S", teams: ["C", "D", "F", "G"] },
      //   { date: "01.21", day: "TUE.", type: "N", teams: ["A", "B", "F", "G"] },
      // ],
      isLoading: false,
      previewBlockWidth: 0,
      turnNextInterval: null,
      turnNextSec: 5,
      pageInfo: [1, 2],
      page: 1,
      transparent: "rgba(0,0,0,0)",
      active: "",
      season: {},
      teams: {},
      showDetail: "",
    };
  },
  computed: {
    teamPlayers: function() {
      return this.teamLine1.concat(this.teamLine2);
    },
    previewPlayers: function() {
      return this.active.team ? this.findTeam(this.active.team).players : [];
    },
    activeColor: function() {
      return this.active.team
        ? this.findTeam(this.active.team).color
        : "#000000";
    },
    cfgs: function() {
      let color = this.active.team ? this.findTeam(this.active.team).color : "";
      let borderW = this.previewBlockWidth > 600 ? 2 : 1;
      return {
        lineLength: this.previewBlockWidth / 3.5,
        borderWidth: borderW,
        dotWidth: 6,
        borderColor: color,
        delayTime: 0,
        animationTime: 1000,
        borderCover: true,
        visMode: "3",
        maxValueFullRate: 0.7,
      };
    },
  },
  methods: {
    previewBlock: function() {
      this.previewBlockWidth = this.$refs[
        "playerPreview"
      ].getBoundingClientRect().width;
    },
    roundDecimalPercentDraw: function(val, precision) {
      var data =
        Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
          Math.pow(10, precision || 0) || 0;
      return data;
    },
    roundDecimal: function(val, precision) {
      var data =
        Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
        Math.pow(10, precision || 0);
      return data || "-";
    },
    roundDecimalPercent: function(val, precision) {
      var data =
        Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
          Math.pow(10, precision || 0) || "-";
      return data !== "-" ? data + "%" : data;
    },
    getTriangleTextCss: function() {
      let width = this.previewBlockWidth / 5;
      let fontSize = width / (1 + 3) - 10;
      return {
        "font-size": fontSize + "px",
        "line-height": fontSize + 10 + "px",
        color: this.activeColor,
      };
    },
    getTriangleData: function(id) {
      let player = this.findPlayer(id).details;
      let color = this.active.team ? this.findTeam(this.active.team).color : "";
      var fill = "rgba(255,255,255,.6)";
      var data = [
        {
          color: color,
          fill: fill,
          rates: [
            this.roundDecimalPercentDraw(
              (player.tsumoCount / player.allHands) * 100,
              2
            ),
            this.roundDecimalPercentDraw(
              (player.winCount / player.allHands) * 100,
              2
            ),
            this.roundDecimalPercentDraw(
              (player.loseCount / player.allHands) * 100,
              2
            ),
          ],
        },
      ];
      return data;
    },
    titleTextCss(text) {
      if (!text)
        return {
          "font-size": 10 + "px",
          "line-height": 10 + 10 + "px",
          "background-color": "#000",
        };
      //即時計算應該顯示的位置與顏色
      let width = this.previewBlockWidth / 5;
      let fontSize =
        width / (1 + Math.max(text.split("\n").map((t) => t.length))) - 5;
      // let fontSize = ((width)/(1+textLength)-10);
      return {
        "font-size": fontSize + "px",
        "line-height": fontSize + 10 + "px",
        "background-color": this.activeColor,
      };
    },
    valueTextCss(text) {
      if (!text)
        return {
          "font-size": 10 + "px",
          "line-height": 10 + 10 + "px",
          "background-color": "#000",
        };
      //即時計算應該顯示的位置與顏色
      let width = this.previewBlockWidth / 5;
      let fontSize =
        width / (1 + Math.max(text.split("\n").map((t) => t.length)));
      // let fontSize = ((width)/(1+textLength)-10);
      if (fontSize < 14) {
        fontSize = 14;
      }
      if (fontSize > 30) {
        fontSize = 30;
      }
      return {
        "font-size": fontSize + "px",
      };
    },
    toggleAnimation: function(toggle) {
      return toggle ? "slideInUp animated" : "";
    },
    getTeamsDetail: async function() {
      this.teamPlayers.forEach(async (team) => {
        await team.players.forEach(async (id) => {
          this.players.filter(
            (el) => el.id == id
          )[0].details = await this.getPlayerDetail(id);
        });
      });
    },
    changeTeam: async function(teamName) {
      this.isLoading = false;
      let team = this.findTeam(teamName);
      if (this.active.team !== team.team) {
        this.turnNextInfo();
      }
      this.isLoading = true;
      this.active = team;
      console.dir(this.$refs.playerPreview);
      this.$nextTick(() => {
        let h =
          this.$refs.scheduleBlock.getBoundingClientRect().height +
          this.$refs.playerPreview.getBoundingClientRect().height;
        this.$parent.$parent.$refs.main.scrollTo({
          top: h,
          behavior: "smooth",
        });
      });
    },
    findTeam: function(team) {
      return this.teamPlayers.filter((el) => el.team == team)[0];
    },
    findPlayer: function(id) {
      return this.players.filter((el) => el.id == id)[0];
    },
    getPlayerDetail: async function(id) {
      return await this.$store
        .dispatch("getPlayerDetail", { credentials: id })
        .then((res) => {
          if (!res.errorCode) {
            return res.data;
          }
        });
    },
    turnNextInfo: function() {
      this.turnNextInterval = clearInterval(this.turnNextInterval);
      this.turnNextInterval = setInterval(() => {
        let index = this.pageInfo.indexOf(this.page);
        this.page = this.pageInfo[index + 1]
          ? this.pageInfo[index + 1]
          : this.pageInfo[0];
      }, this.turnNextSec * 1000);
    },
  },
  mounted() {
    let $this = this;
    this.previewBlock();
    this.getTeamsDetail();
    window.addEventListener("resize", function() {
      $this.previewBlock();
    });
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/event";
@import "../../assets/style/playerGameBaseData";
.hidden {
  display: none;
}
.notice {
  width: 100%;
  text-align: center;
  font-family: Noto Sans TC;
  color: $main-color-1;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  a {
    color: $main-color-1;
  }

  font-weight: 900;
  @include responsive("min", 768px) {
    font-size: 1.5rem;
  }
  @include responsive("max", 767.5px) {
    font-size: 1rem;
  }
}
</style>
